import React from 'react';
import DartboardAuth from '@/components/blocks/dartboard';

interface DartboardModalProps {
  isOpen: boolean;
  onClose: () => void;
  onContinue: () => void;
}

const DartboardModal: React.FC<DartboardModalProps> = ({ isOpen, onClose, onContinue }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50">
      <div className="absolute inset-0 bg-black bg-opacity-50" onClick={onClose}></div>
      <div className="bg-white rounded-lg shadow-xl p-6 max-w-md w-full relative z-10">
        {/* Pass the onContinue callback directly to DartboardAuth */}
        <DartboardAuth onContinue={onContinue} />
      </div>
    </div>
  );
};

export default DartboardModal;