// app/frontend/contexts/auth-context.tsx
import React, { createContext, useContext, useEffect, useState } from "react";
import { useFirebase } from "@/contexts/firebase-context";

interface User {
  id: string;
  name: string;
  email?: string;
  first_name?: string;
  last_name?: string;
  avatar_url?: string;
  auth_method?: string;
}

interface AuthContextType {
  user: User | null;
  isAuthenticated: boolean;
  isLoading: boolean;
  login: (email: string, password: string) => Promise<boolean>;
  logout: () => Promise<void>;
  checkCurrentUser: () => Promise<void>;
}

const AuthContext = createContext<AuthContextType>(null!);

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [user, setUser] = useState<User | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const { signInWithToken, logOut } = useFirebase();

  async function checkCurrentUser() {
    try {
      const res = await fetch("/api/auth/current_user", {
        credentials: "include",
      });
      const data = await res.json();
      
      if (data.logged_in && data.user) {
        setUser(data.user);
        
        // Get Firebase token and authenticate with Firebase
        try {
          const tokenRes = await fetch("/api/firebase/auth/token", {
            method: "POST",
            credentials: "include",
            headers: {
              "Content-Type": "application/json"
            }
          });
          
          if (tokenRes.ok) {
            const tokenData = await tokenRes.json();
            if (tokenData.token) {
              await signInWithToken(tokenData.token);
            }
          }
        } catch (firebaseError) {
          console.error("Failed to authenticate with Firebase:", firebaseError);
          // Continue even if Firebase auth fails
        }
      } else {
        setUser(null);
      }
    } catch (error) {
      console.error("Failed to check current user:", error);
      setUser(null);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    checkCurrentUser();
  }, []);

  async function login(email: string, password: string) {
    try {
      const res = await fetch("/api/auth/login", {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, password }),
      });
      const data = await res.json();
      
      if (res.ok && data.success) {
        await checkCurrentUser();
        return true;
      } else {
        console.error("Login error:", data.error);
        return false;
      }
    } catch (error) {
      console.error("Login request failed:", error);
      return false;
    }
  }

  async function logout() {
    setIsLoading(true);
    try {
      // Logout from Firebase first
      await logOut();
      
      // Then logout from Rails
      const res = await fetch("/api/auth/logout", {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      });
      
      if (res.ok) {
        setUser(null);
      }
    } catch (error) {
      console.error("Logout failed:", error);
    } finally {
      setIsLoading(false);
    }
  }

  const value: AuthContextType = {
    user,
    isAuthenticated: !!user,
    isLoading,
    login,
    logout,
    checkCurrentUser,
  };

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
};

export function useAuth() {
  return useContext(AuthContext);
}