import React, { useState, useEffect } from 'react';

// Define the type for the dart position
interface DartPosition {
  x: number;
  y: number;
}

interface DartboardAuthProps {
  onContinue?: () => void;
}

const DartboardAuth: React.FC<DartboardAuthProps> = ({ onContinue }) => {
  const [dartPositions, setDartPositions] = useState<DartPosition[]>([]);
  const [authenticated, setAuthenticated] = useState<boolean>(false);
  const [showWave, setShowWave] = useState<boolean>(false);
  const [wavePosition, setWavePosition] = useState<DartPosition | null>(null);
  
  const handleDartboardClick = (e: React.MouseEvent<SVGSVGElement>): void => {
    // Get the dartboard SVG element
    const dartboard = e.currentTarget;
    const rect = dartboard.getBoundingClientRect();
    
    // Calculate center of the dartboard
    const centerX = rect.width / 2;
    const centerY = rect.height / 2;
    
    // Calculate click position relative to the dartboard
    const clickY = e.clientY - rect.top;
    
    // Place the dart completely to the right of the board with random variation
    const randomOffset = Math.random() * 50 + 20; // Random distance from the edge
    const randomYVariation = (Math.random() - 0.5) * 60; // Random vertical variation
    
    const dartX = rect.width + randomOffset;
    const dartY = centerY + randomYVariation;
    
    const newDart = { x: dartX, y: dartY };
    setDartPositions([...dartPositions, newDart]);
    setWavePosition(newDart);
    setShowWave(true);
    
    // After a short delay, hide the wave animation
    setTimeout(() => {
      setShowWave(false);
      if (!authenticated) {
        setAuthenticated(true);
      }
    }, 800);
  };
  
  const handleContinue = (): void => {
    // Call the provided onContinue function if it exists
    if (onContinue) {
      onContinue();
    }
    console.log("Continue clicked");
  };
  
  return (
    <div className="dartboard-auth flex flex-col items-center justify-center p-8 rounded-lg shadow-md">
      {/* Text and button container with fixed height to prevent layout shift */}
      <div className="h-36 flex flex-col items-center justify-center mb-4">
        {!authenticated ? (
          <>
            <h2 className="text-xl text-gray-900 font-bold mb-2 text-center">Human Verification Check</h2>
            <p className = "text-gray-900 text-center">To prove you're not a robot, click the <b className='text-green-500'>centre</b> of this dartboard.</p>
          </>
        ) : (
          <>
            <div className="text-xl text-center font-bold text-gray-900 mb-4">
              Oh you're definitely Della alright. Authorisation successful.
            </div>
            
            <button
              onClick={handleContinue}
              className="px-6 py-2 bg-cyan-800 text-white font-medium rounded-md hover:bg-cyan-900 transition-colors"
            >
              Continue
            </button>
          </>
        )}
      </div>
      
      {/* Dartboard container */}
      <div className="relative w-72 h-72 flex items-center justify-center">
        <svg 
          width="256" 
          height="256" 
          viewBox="0 0 256 256" 
          className="cursor-pointer"
          onClick={handleDartboardClick}
        >
          {/* Dartboard rings */}
          <circle cx="128" cy="128" r="128" fill="black" />
          <circle cx="128" cy="128" r="102" fill="#E41B17" /> {/* Red */}
          <circle cx="128" cy="128" r="76" fill="black" />
          <circle cx="128" cy="128" r="50" fill="#E41B17" /> {/* Red */}
          <circle cx="128" cy="128" r="25" fill="black" />
          
          {/* Bull's eye (bright green) */}
          <circle cx="128" cy="128" r="12" fill="#00FF00" /> {/* Bright Green */}
        </svg>
        
        {/* Container to show darts landing outside the board */}
        {dartPositions.map((position, index) => (
          <div 
            key={index}
            className="absolute w-4 h-4 bg-yellow-400 rounded-full border-2 border-gray-800 shadow-md"
            style={{ 
              left: `${position.x}px`, 
              top: `${position.y}px`,
              transform: 'translate(-50%, -50%)' 
            }}
          ></div>
        ))}
        
        {/* Wave animation at the last dart's position */}
        {showWave && wavePosition && (
          <div 
            className="absolute pointer-events-none"
            style={{ 
              left: `${wavePosition.x}px`, 
              top: `${wavePosition.y}px`
            }}
          >
            <div className="w-6 h-6 rounded-full border-2 border-yellow-400 opacity-80 animate-ping absolute -translate-x-1/2 -translate-y-1/2"></div>
            <div className="w-12 h-12 rounded-full border-2 border-yellow-400 opacity-60 animate-ping absolute -translate-x-1/2 -translate-y-1/2" style={{ animationDelay: "100ms" }}></div>
            <div className="w-18 h-18 rounded-full border-2 border-yellow-400 opacity-40 animate-ping absolute -translate-x-1/2 -translate-y-1/2" style={{ animationDelay: "200ms" }}></div>
          </div>
        )}
      </div>
    </div>
  );
};

export default DartboardAuth;