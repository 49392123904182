// app/frontend/firebase.ts
import { initializeApp, FirebaseApp } from 'firebase/app';
import { 
  getFirestore, Firestore, 
  collection, doc, query, where, orderBy, limit,
  getDocs, getDoc, setDoc, addDoc, updateDoc, deleteDoc,
  onSnapshot
} from 'firebase/firestore';
import { 
  getAuth, Auth, 
  signInWithCustomToken, 
  signOut, 
  onAuthStateChanged 
} from 'firebase/auth';

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyD92Hm3KMeHjZccjG_Yw4FFldtstjSUNro",
  authDomain: "bg-jellyfish.firebaseapp.com",
  projectId: "bg-jellyfish",
  storageBucket: "bg-jellyfish.firebasestorage.app",
  messagingSenderId: "1094973199155",
  appId: "1:1094973199155:web:079c83cf4c3e115daea446",
  measurementId: "G-CQ5DJVYML4"
};

// Initialize Firebase
let firebaseApp: FirebaseApp;
let firestoreDB: Firestore;
let auth: Auth;

// Initialize Firebase
try {
  firebaseApp = initializeApp(firebaseConfig);
  firestoreDB = getFirestore(firebaseApp);
  auth = getAuth(firebaseApp);
  console.log("Firebase initialized successfully");
} catch (error) {
  console.error("Error initializing Firebase:", error);
  // Create fallback instances to prevent undefined errors
  // These will cause runtime errors if used, but prevent TypeScript errors
  firebaseApp = {} as FirebaseApp;
  firestoreDB = {} as Firestore;
  auth = {} as Auth;
}

// Export initialized services
export { 
  firebaseApp, 
  firestoreDB, 
  auth,
  // Firestore functions
  collection, doc, query, where, orderBy, limit,
  getDocs, getDoc, setDoc, addDoc, updateDoc, deleteDoc,
  onSnapshot,
  // Auth functions 
  signInWithCustomToken, 
  signOut, 
  onAuthStateChanged
};

export default { firebaseApp, firestoreDB, auth };