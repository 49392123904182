import React, { lazy, Suspense } from 'react';
import { ArrowDownToLine, FileText, MessageSquare } from 'lucide-react';

// Use lazy loading for all feature components
const ChatBoxComponent = lazy(() => import('@/features/TranscriptionChat/ChatBox').then(module => ({ default: module.ChatBox })));
const TranscriptionComponent = lazy(() => import('@/features/TranscriptionChat/TranscriptionChatFeature').then(module => ({ default: module.TranscriptionChatFeature })));
const SheetCheckComponent = lazy(() => import('@/features/SheetCheck/ChatPage').then(module => ({ default: module.SheetCheckChatInterface })));
const AdBuilderComponent = lazy(() => import('@/features/AdBuilder/AdBuilder').then(module => ({ default: module.AdBuilderInterface })));

// Feature loader component with suspense
const FeatureLoader = ({ children }: { children: React.ReactNode }) => (
  <Suspense fallback={<div className="flex items-center justify-center w-full h-96">Loading feature...</div>}>
    {children}
  </Suspense>
);

export interface Feature {
  id: string;
  name: string;
  description: string;
  path: string;
  icon: React.ComponentType;
  component: React.ComponentType;
  enabled: boolean;
  permissions?: string[];
  requiresAuth?: boolean;
}

const features: Record<string, Feature> = {
  chat: {
    id: 'chat',
    name: 'Chat Interface',
    description: 'General AI chat interface',
    path: '/',
    icon: MessageSquare,
    component: () => (
      <FeatureLoader>
        <ChatBoxComponent />
      </FeatureLoader>
    ),
    enabled: true,
    requiresAuth: true
  },
  transcription: {
    id: 'transcription',
    name: 'Transcription',
    description: 'Transcribe and analyse audio content',
    path: '/transcription',
    icon: ArrowDownToLine,
    component: () => (
      <FeatureLoader>
        <TranscriptionComponent />
      </FeatureLoader>
    ),
    enabled: true,
    requiresAuth: true
  },
  sheetCheck: {
    id: 'sheet-check',
    name: 'Sheet Check',
    description: 'Google Sheets integration',
    path: '/sheet-check',
    icon: FileText,
    component: () => (
      <FeatureLoader>
        <SheetCheckComponent />
      </FeatureLoader>
    ),
    enabled: true,
    requiresAuth: true
  },
  adbuilder: {
    id: 'adbuilder',
    name: 'Ad Builder',
    description: 'Transcribe and analyse audio content',
    path: '/adbuilder',
    icon: ArrowDownToLine,
    component: () => (
      <FeatureLoader>
        <AdBuilderComponent />
      </FeatureLoader>
    ),
    enabled: true,
    requiresAuth: true
  }
};

// Helper functions to work with features
export const getFeatures = () => Object.values(features).filter(f => f.enabled);
export const getFeatureById = (id: string) => features[id];
export const getFeatureByPath = (path: string) => 
  Object.values(features).find(f => f.path === path || (path === '/' && f.path === '/'));

export default features;