import React, { createContext, useContext, useState, useEffect } from 'react';

interface ChatContextProps {
  provider: string;
  model: string;
  temperature: number;
  isReasoningModel: boolean;
  systemPromptPreset: string;
  setProvider: (provider: string) => void;
  setModel: (model: string) => void;
  setTemperature: (temperature: number) => void;
  setSystemPromptPreset: (preset: string) => void;
}

const ChatContext = createContext<ChatContextProps | undefined>(undefined);

export const ChatProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [provider, setProviderInternal] = useState('OpenAI');
  const [model, setModel] = useState('gpt-4o');
  const [temperature, setTemperature] = useState(0.9);
  const [isReasoningModel, setIsReasoningModel] = useState(false);
  const [modelOptions, setModelOptions] = useState<ModelOption[]>([]);
  const [modelsByProvider, setModelsByProvider] = useState<Record<string, ModelOption[]>>({});
  const [systemPromptPreset, setSystemPromptPreset] = useState('default');

  // Wrapper for setProvider to ensure consistent case handling
  const setProvider = (newProvider: string) => {
    console.log(`Setting provider to: ${newProvider}`);
    setProviderInternal(newProvider.toLowerCase());
  };

  useEffect(() => {
    fetch('/api/ai_models')
      .then(response => response.json())
      .then(data => {
        console.log("AI models data:", data);
        setModelsByProvider(data.models);
        
        // Use lowercase provider key for consistency
        const providerKey = provider.toLowerCase();
        console.log(`Looking for models with provider key: ${providerKey}`);
        
        // Check available keys in data.models
        console.log("Available provider keys:", Object.keys(data.models));
        
        setModelOptions(data.models[providerKey] || []);
        const initialModel = (data.models[providerKey] || []).find((m: ModelOption) => m.value === model);
        setIsReasoningModel(initialModel?.reasoning || false);
      })
      .catch(error => console.error('Failed to fetch model configuration:', error));
  }, []);

  useEffect(() => {
    // Use lowercase provider key for consistency
    const providerKey = provider.toLowerCase();
    console.log(`Provider changed to: ${provider}, looking up models with key: ${providerKey}`);
    
    const newModels = modelsByProvider[providerKey] || [];
    console.log(`Found ${newModels.length} models for provider: ${providerKey}`);
    
    setModelOptions(newModels);
    if (newModels.length > 0 && !newModels.find(m => m.value === model)) {
      const firstModel = newModels[0];
      console.log(`Setting model to first available: ${firstModel.value}`);
      setModel(firstModel.value);
      setIsReasoningModel(firstModel.reasoning || false);
    }
  }, [provider, modelsByProvider]);

  useEffect(() => {
    const selectedModel = modelOptions.find((m: ModelOption) => m.value === model);
    setIsReasoningModel(selectedModel?.reasoning || false);
  }, [model, modelOptions]);

  return (
    <ChatContext.Provider value={{ 
      provider, 
      model, 
      temperature, 
      isReasoningModel, 
      systemPromptPreset,
      setProvider, 
      setModel, 
      setTemperature,
      setSystemPromptPreset
    }}>
      {children}
    </ChatContext.Provider>
  );
};

export const useChat = () => {
  const context = useContext(ChatContext);
  if (!context) {
    throw new Error('useChat must be used within a ChatProvider');
  }
  return context;
};

interface ModelOption {
  value: string;
  label: string;
  description?: string;
  reasoning?: boolean;
}