import React, { useState } from "react";
import { cn } from "@/lib/utils";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { useAuth } from "@/contexts/auth-context";
import { useNavigate } from "react-router-dom";
import DartboardModal from "./DartboardModal";

export function LoginForm({ className, ...props }: React.ComponentProps<"form">) {
  const { login } = useAuth();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [showDartboardModal, setShowDartboardModal] = useState(false);
  const [loginSuccess, setLoginSuccess] = useState(false);
  
  // Check if it's Della's email
  const isDellaEmail = (email: string) => {
    return email.toLowerCase() === "della.wales@thisisexp.com";
  };
  
  async function handleSubmit(e: React.FormEvent) {
    e.preventDefault();
    setError("");
    setIsLoading(true);
    
    // First attempt the login to verify credentials
    const success = await login(email, password);
    
    if (success) {
      if (isDellaEmail(email)) {
        // If it's Della, show the dartboard modal instead of redirecting
        setLoginSuccess(true);
        setIsLoading(false);
        setShowDartboardModal(true);
      } else {
        // For everyone else, continue with normal login flow
        navigate("/");
      }
    } else {
      setError("Invalid credentials");
      setIsLoading(false);
    }
  }
  
  function handleGoogleLogin(e: React.MouseEvent) {
    e.preventDefault();
    window.location.href = "/auth/google_oauth2";
  }
  
  // Called when Della clicks "Continue" on the dartboard
  function handleDartboardContinue() {
    setShowDartboardModal(false);
    navigate("/"); // Now complete the login flow
  }

  return (
    <>
      <form className={cn("flex flex-col gap-6", className)} {...props} onSubmit={handleSubmit}>
        <div className="flex flex-col items-center gap-2 text-center">
          <h1 className="text-2xl font-bold">Login to your account</h1>
          <p className="text-muted-foreground text-sm">
            Enter your email below to login
          </p>
        </div>
        {error && (
          <div className="bg-destructive/15 text-destructive px-4 py-2 rounded-md text-sm">
            {error}
          </div>
        )}
        <div className="grid gap-6">
          <div className="grid gap-3">
            <Label htmlFor="email">Email</Label>
            <Input
              id="email"
              type="email"
              placeholder="admin@boysandgirls.ie"
              required
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="grid gap-3">
            <Label htmlFor="password">Password</Label>
            <Input
              id="password"
              type="password"
              required
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <Button type="submit" className="w-full" disabled={isLoading}>
            {isLoading ? "Logging in..." : "Login"}
          </Button>
          <div className="relative text-center text-sm">
            <span className="bg-background text-muted-foreground px-2">
              Or continue with
            </span>
          </div>
          <Button
            variant="outline"
            className="w-full"
            type="button"
            onClick={handleGoogleLogin}
          >
            <svg className="h-5 w-5 mr-2" viewBox="0 0 24 24">
              <path
                d="M22.56 12.25c0-.78-.07-1.53-.2-2.25H12v4.26h5.92c-.26 1.37-1.04 2.53-2.21 3.31v2.77h3.57c2.08-1.92 3.28-4.74 3.28-8.09z"
                fill="#4285F4"
              />
              <path
                d="M12 23c2.97 0 5.46-.98 7.28-2.66l-3.57-2.77c-.98.66-2.23 1.06-3.71 1.06-2.86 0-5.29-1.93-6.16-4.53H2.18v2.84C3.99 20.53 7.7 23 12 23z"
                fill="#34A853"
              />
              <path
                d="M5.84 14.09c-.22-.66-.35-1.36-.35-2.09s.13-1.43.35-2.09V7.07H2.18C1.43 8.55 1 10.22 1 12s.43 3.45 1.18 4.93l2.85-2.22.81-.62z"
                fill="#FBBC05"
              />
              <path
                d="M12 5.38c1.62 0 3.06.56 4.21 1.64l3.15-3.15C17.45 2.09 14.97 1 12 1 7.7 1 3.99 3.47 2.18 7.07l3.66 2.84c.87-2.6 3.3-4.53 6.16-4.53z"
                fill="#EA4335"
              />
            </svg>
            Login with Google
          </Button>
        </div>
        <div className="text-center text-sm text-muted-foreground">
          Only @boysandgirls.ie, @thisisexp.com and @circlecontent.ie email accounts can login with Google
        </div>
      </form>
      
      {/* Dartboard modal for Della */}
      <DartboardModal 
        isOpen={showDartboardModal} 
        onClose={() => setShowDartboardModal(false)}
        onContinue={handleDartboardContinue}
      />
    </>
  );
}