import React from 'react';
import { useLocation } from 'react-router-dom';
import { getFeatureByPath } from '@/features/registry';

interface FeatureRendererProps {
  fallback?: React.ReactNode;
}

export const FeatureRenderer: React.FC<FeatureRendererProps> = ({ fallback }) => {
  const location = useLocation();
  const currentPath = location.pathname;
  
  const feature = getFeatureByPath(currentPath);
  
  if (!feature) {
    return <>{fallback}</> || <div>Feature not found</div>;
  }
  
  const FeatureComponent = feature.component;
  return <FeatureComponent />;
};

export default FeatureRenderer;