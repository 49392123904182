import * as React from "react"
import {
  BookOpen,
  Frame,
  Map,
  PieChart,
  SquareTerminal,
} from "lucide-react"

import { NavMain } from "@/components/layouts/sections/sidebar/sidebar-nav-main"
import { NavProjects } from "@/components/layouts/sections/sidebar/sidebar-nav-projects"
import { NavUser } from "@/components/layouts/sections/sidebar/sidebar-nav-user"
import FeatureNavigation from "@/components/layouts/sections/sidebar/sidebar-nav-features"
import {
  Sidebar,
  SidebarContent,
  SidebarFooter,
  SidebarHeader,
  SidebarRail,
} from "@/components/ui/sidebar"
import { useAuth } from "@/contexts/auth-context"

// Import the logo image
import bgLogo from "@/assets/images/bglogo.png"

// This is sample data.
const data = {
  navMain: [
    {
      title: "Playground",
      url: "#",
      icon: SquareTerminal,
      isActive: true,
      items: [
        {
          title: "Transcription",
          url: "#",
        },
        {
          title: "B+GPT",
          url: "#",
        }
      ],
    },
    {
      title: "Documentation",
      url: "#",
      icon: BookOpen,
      items: [
        {
          title: "Intro tbc",
          url: "#",
        }
      ],
    },
  ],
  projects: [
    {
      name: "Transcription",
      url: "#",
      icon: Frame,
    },
    {
      name: "Tenderbot",
      url: "#",
      icon: PieChart,
    },
    {
      name: "Contact Report",
      url: "#",
      icon: Map,
    },
  ],
}

export function AppSidebar({ ...props }: React.ComponentProps<typeof Sidebar>) {
  const { user } = useAuth();
  
  const userData = user ? {
    name: user.first_name || user.name || "User",
    email: user.email || user.id || "user@example.com",
    avatar: user.avatar_url || "",
    fullName: `${user.first_name || ''} ${user.last_name || ''}`.trim() || user.name || "User"
  } : {
    name: "Guest",
    email: "guest@example.com",
    avatar: "",
    fullName: "Guest User"
  };

  return (
    <Sidebar collapsible="icon" {...props}>
      <SidebarHeader>
        <div className="flex items-center justify-center px-4 py-2">
          <img 
            src={bgLogo} 
            alt="Boys & Girls Logo" 
            className="h-12 w-auto"
          />
        </div>
      </SidebarHeader>
      <SidebarContent>
        <FeatureNavigation />
        <NavMain items={data.navMain} />
        <NavProjects projects={data.projects} />
      </SidebarContent>
      <SidebarFooter>
        <NavUser user={userData} />
      </SidebarFooter>
      <SidebarRail />
    </Sidebar>
  )
}
