// app/frontend/entrypoints/App.tsx
import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import '@styles/application.css';
import ShellPage from '@components/layouts/shell';
import { ThemeProvider } from "@/lib/theme-provider";
import { AuthProvider } from '@/contexts/auth-context';
import { FirebaseProvider } from '@/contexts/firebase-context';
import LoginPage from '@/components/layouts/login';
import { ProtectedRoute } from '@/lib/protected-route';
import { getFeatures, type Feature } from '@/features/registry';
import FeatureRenderer from '@/lib/feature-renderer';
import { ChatProvider } from '@/contexts/chat-context';

document.addEventListener('DOMContentLoaded', () => {
  const container = document.getElementById('root');
  if (container) {
    const root = createRoot(container);
    const features = getFeatures();
    
    root.render(
      <React.StrictMode>
        <FirebaseProvider>
          <AuthProvider>
            <ThemeProvider defaultTheme='dark' storageKey='vite-ui-theme'>
              <BrowserRouter basename="/">
                <ChatProvider>
                  <Routes>
                    <Route path="/login" element={<LoginPage />} />
                    {features.map((feature: Feature) => (
                      <Route
                        key={feature.id}
                        path={feature.path}
                        element={
                          <ProtectedRoute>
                            <ShellPage>
                              <FeatureRenderer />
                            </ShellPage>
                          </ProtectedRoute>
                        }
                      />
                    ))}
                    <Route path="*" element={<Navigate to="/" replace />} />
                  </Routes>
                </ChatProvider>
              </BrowserRouter>
            </ThemeProvider>
          </AuthProvider>
        </FirebaseProvider>
      </React.StrictMode>
    );
  }
});