import React from 'react';
import { useLocation, Link } from 'react-router-dom';
import { getFeatures, type Feature } from '@/features/registry';
import {
  SidebarGroup,
  SidebarGroupLabel,
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
} from '@/components/ui/sidebar';

export const FeatureNavigation: React.FC = () => {
  const location = useLocation();
  const features = getFeatures();
  
  return (
    <SidebarGroup>
      <SidebarGroupLabel>Features</SidebarGroupLabel>
      <SidebarMenu>
        {features.map((feature: Feature) => (
          <SidebarMenuItem key={feature.id}>
            <SidebarMenuButton 
              asChild
              isActive={location.pathname === feature.path}
              tooltip={feature.description}
            >
              <Link to={feature.path}>
                <feature.icon />
                <span>{feature.name}</span>
              </Link>
            </SidebarMenuButton>
          </SidebarMenuItem>
        ))}
      </SidebarMenu>
    </SidebarGroup>
  );
};

export default FeatureNavigation;