// app/frontend/contexts/firebase-context.tsx
import React, { createContext, useContext, useEffect, useState } from 'react';
import { 
  auth, 
  firestoreDB, 
  onAuthStateChanged, 
  signInWithCustomToken,
  signOut
} from '@/firebase';
import { User } from 'firebase/auth';

interface FirebaseContextType {
  auth: typeof auth;
  firestore: typeof firestoreDB;
  user: User | null;
  isLoading: boolean;
  signInWithToken: (token: string) => Promise<void>;
  logOut: () => Promise<void>;
}

const FirebaseContext = createContext<FirebaseContextType | undefined>(undefined);

export const FirebaseProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [user, setUser] = useState<User | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (firebaseUser) => {
      setUser(firebaseUser);
      setIsLoading(false);
    });

    return () => unsubscribe();
  }, []);

  const signInWithToken = async (token: string) => {
    try {
      await signInWithCustomToken(auth, token);
    } catch (error) {
      console.error('Error signing in with custom token:', error);
      throw error;
    }
  };

  const logOut = async () => {
    try {
      await signOut(auth);
    } catch (error) {
      console.error('Error signing out:', error);
      throw error;
    }
  };

  const value = {
    auth,
    firestore: firestoreDB,
    user,
    isLoading,
    signInWithToken,
    logOut
  };

  return (
    <FirebaseContext.Provider value={value}>
      {children}
    </FirebaseContext.Provider>
  );
};

export const useFirebase = () => {
  const context = useContext(FirebaseContext);
  if (context === undefined) {
    throw new Error('useFirebase must be used within a FirebaseProvider');
  }
  return context;
};